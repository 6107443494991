export default {
  s3: {
    REGION: 'ap-northeast-2',
    BUCKET: 'eun-project-upload'
  },
  apiGateway: {
    REGION: 'ap-northeast-2',
    URL: 'https://gk68ntrfy9.execute-api.ap-northeast-2.amazonaws.com/dev'
  },
  cognito: {
    REGION: 'ap-northeast-2',
    USER_POOL_ID: 'ap-northeast-2_QbG2W0THN',
    APP_CLIENT_ID: '1qd9lvh13pcqjbais1pv45f893',
    IDENTITY_POOL_ID: 'ap-northeast-2:f595a494-0bbd-489f-997c-9f23f64f0399'
  }
};
